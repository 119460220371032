import { defineComponent } from 'vue';
import { verifyAssOrg } from '@/apis/index';
import { isMobile } from '../../utils';
export default defineComponent({
    name: 'AssociatedOrganization',
    data() {
        return {
            state: '',
            companyname: '',
            errmsg: '',
        };
    },
    async created() {
        if (isMobile) {
            alert('请前往电脑端打开此链接');
            return;
        }
        if (this.$route.query) {
            const enginecode = this.$route.query.enginecode || '';
            const guid = this.$route.query.guid || '';
            // if (isMobile) {
            //   const appidStr = this.$route.query.appid || '';
            //   let appid = '';
            //   let code = '';
            //   if (appidStr.indexOf('?code=') > -1) {
            //     appid = appidStr.split('?')[0];
            //     code = appidStr.split('?')[1].split('=')[1];
            //   }
            //   const url = `${window.location.origin}${window.location.pathname}#/assorg-mobile?enginecode=${enginecode}&guid=${guid}&appid=${appid}&code=${code}`;
            //   window.location.replace(url);
            //   return;
            // }
            const res = await verifyAssOrg({
                EngineCode: enginecode,
                Code: guid
            });
            this.companyname = (res.ReturnData && res.ReturnData.Data && res.ReturnData.Data.companyname) || '';
            this.errmsg = res.errmsg || res.ErrorMessage || (res.ReturnData && res.ReturnData.Data && res.ReturnData.Data.errormsg);
            if (res.errcode !== 0) {
                switch (res.errcode) {
                    case 205003: // 已关闭
                        this.state = 'isClose';
                        break;
                    case 205004: // 非付费
                        this.state = 'isNonPaid';
                        break;
                    default: // 其他
                        this.state = 'isOthers';
                        break;
                }
            }
            else {
                window.sessionStorage.setItem('origin_fromPath', 'associatedOrganization');
                window.sessionStorage.setItem('associatedOrg_enginecode', enginecode);
                window.sessionStorage.setItem('associatedOrg_guid', guid);
                this.$router.push({
                    name: 'Login',
                    query: {
                        enginecode,
                        guid,
                        fromPath: 'associatedOrganization',
                    },
                });
            }
        }
    },
    methods: {}
});
